@import "common/_mixins.scss";

@include font-face("Roboto", "../fonts/RobotoRegular/RobotoRegular", 400);
@include font-face("Roboto", "../fonts/RobotoMedium/RobotoMedium", 500);
@include font-face("Roboto", "../fonts/RobotoBold/RobotoBold", 700);

@include font-face("SFUI Text", "../fonts/SFUITextHeavy/SFUITextHeavy", 800);
@include font-face("SFUI Text", "../fonts/SFUITextBold/SFUITextBold", 700);
@include font-face("SFUI Text", "../fonts/SFUITextMedium/SFUITextMedium", 500);
@include font-face("SFUI Text", "../fonts/SFUITextRegular/SFUITextRegular", 400);
@include font-face("SFUI Text", "../fonts/SFUITextLight/SFUITextLight", 300);


@include font-face("Bebas Neue", "../fonts/BebasNeueBold/BebasNeueBold", 700);
@include font-face("Bebas Neue", "../fonts/BebasNeueBook/BebasNeueBook", 500);
@include font-face("Bebas Neue", "../fonts/BebasNeueRegular/BebasNeueRegular", 400);

@include font-face("Galaxie Polaris", "../fonts/GalaxiePolaris-Bold/GalaxiePolaris-Bold", 700);