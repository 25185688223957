.header {
	min-height: 959px;
	padding-top: 23px;
	background:
	url(../img/header-bg.jpg) 50% 0 no-repeat,
	linear-gradient(to right, #e5e4e8 0%,#d2d1d8 100%);
	.phones {
		text-align: right;
		.phone {
			color: #2d2c2c;
			font-family: "Bebas Neue";
			font-size: 29px;
			font-weight: 400;
			line-height: 49px;
			span {
				display: inline-block;
				margin-left: 14px;
				color: #2d2c2c;
				font-family: "SFUI Text";
				font-size: 15px;
				font-weight: 400;
				line-height: 32px;
			}
		}
		.phone1 {
			margin-right: 60px;
			padding-left: 27px;
			background: url(../img/phone-icon.png) 0 50% no-repeat;
		}
	}
	.topline {
		margin-bottom: 66px;
	}
	.title {
		color: #1c1b1b;
		font-family: "Bebas Neue";
		font-size: 108px;
		font-weight: 400;
		line-height: 80px;
		text-transform: uppercase;
		max-width: 450px;
		vertical-align: top;
		span {
			display: inline-block;
			color: #ffffff;
			font-family: "Bebas Neue";
			font-size: 47px;
			font-weight: 500;
			line-height: 74px;
			text-transform: uppercase;
			border-radius: 6px;
			background-color: #e25050;
			text-align: center;
			padding-left: 17px;
			padding-right: 17px;
			transform: translateY(-20px);
		}
	}
	.undertitle {
		color: #1c1b1b;
		font-family: "Bebas Neue";
		font-size: 35px;
		margin-top: 20px;
		font-weight: 400;
		line-height: 40px;
		text-transform: uppercase;
		margin-bottom: 40px;
	}
	.desc {
		color: #070707;
		font-family: "SFUI Text";
		font-size: 18px;
		font-weight: 400;
		line-height: 27px;
		max-width: 570px;
		margin-bottom: 50px;
	}
	.date {
		color: #2d2c2c;
		font-family: "SFUI Text";
		font-size: 32px;
		font-weight: 300;
		display: inline-block;
		vertical-align: top;
		margin-right: 25px;
	}
	.info {
		color: #2d2c2c;
		font-family: "SFUI Text";
		font-size: 16px;
		font-weight: 300;
		max-width: 140px;
		display: inline-block;
		vertical-align: top;
	}
	.date-block {
		margin-bottom: 35px;
	}
	.info-block {
		margin-top: 55px;
		border-top: 1px #d4d3d7 solid;
		max-width: 565px;
		padding-top: 30px;
		.text {
			display: inline-block;
			vertical-align: top;
			opacity: 0.92;
			color: #2d2c2c;
			font-family: "SFUI Text";
			font-size: 14px;
			font-weight: 300;
			line-height: 20px;
			max-width: 255px;
			position: relative;
			span {
				position: absolute;
				top: 0;
				right: calc(100% + 5px);
			}
		}
	}
	.play-icon {
		display: block;
		width: 159px;
		height: 159px;
		background: url(../img/play-icon2.svg) 50% no-repeat;
		background-size: 159px 159px;
	}
}