.subm {
	cursor: pointer;
	border: none;
	display: block;
	margin: 0 auto;
	transition: all 0.3s linear;
	&:hover,
	&:focus {
		transform: scale(1.02);
	}
}

.callback-btn {
	margin-top: 5px;
	display: inline-block;
	border-radius: 2px;
	border: 1px solid #bd2626;
	width: 160px;
	line-height: 45px;
	align-self: flex-end;
	text-align: center;
	color: #2d2c2c;
	font-family: "Bebas Neue";
	font-size: 17px;
	font-weight: 700;
	letter-spacing: 0.68px;
	&:hover {
		color: #fff;
		background-color: #bd2626;
	}
}

.btn {
	box-shadow: 0 7px 24px rgba(247, 77, 77, 0.43);
	border-radius: 36px;
	background-color: #e25050;
	min-width: 188px;
	text-align: center;
	padding-left: 50px;
	padding-right: 50px;
	line-height: 74px;
	color: #ffffff;
	font-family: "SFUI Text";
	font-size: 17px;
	font-weight: 400;
	b {
		font-weight: 800;
	}
}

.package-btn {
	padding: 0;
	width: 183px;
	line-height: 62px;
}