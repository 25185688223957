form.standart-form {
	max-width: 400px;
	text-align: center;
	position: relative;
	.part {
		margin: 0 auto;
		display: inline-block;
		min-width: 300px;
	}
	.label {
		text-align: left;
		font-weight: 400;
		font-size: 15px;
		line-height: 22px;
	}
	input.name,
	input.email,
	input.phone {
		width: 300px;
		max-width: 300px;
		height: 45px;
		background-color: #fff;
		border: 1px solid #dcdcdc;
		border-radius: 3px;
		padding-left: 15px;
		font-weight: 400;
		font-size: 16px;
		line-height: 45px;
		margin-bottom: 15px;
		display: block;
	}

	&:before {
		content: "";
		display: none;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: rgba(255,255,255, .7) url(../img/loading.svg) 50% no-repeat;
		z-index: 10;
	}
	&.loading {
		&:before {
			display: block;
		}
	}
}