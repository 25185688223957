.sec1 {
	padding-top: 145px;
	padding-bottom: 145px;
	background: #c94948 url(../img/sec1.jpg) 50% 0 no-repeat;
	background-size: cover;
	.sec-title {
		max-width: 650px;
		color: #ffffff;
		font-family: "Bebas Neue";
		font-size: 80px;
		font-weight: 400;
		line-height: 77px;
		margin-bottom: 50px;
		span {
			display: block;
			font-size: 42px;
			font-weight: 500;
		}
	}
	// .video {
	// 	position: relative;
	// 	padding-bottom: 56.25%;
	// 	height: 0;
	// 	overflow: hidden;
	// 	iframe {
	// 		position: absolute;
	// 		top: 0;
	// 		left: 0;
	// 		width: 100%;
	// 		height: 100%;
	// 	}
	// }
	.video {
		height: 401px;
		background: url(../img/videobg.jpg) 50% no-repeat;
		background-size: cover;
		text-align: center;
		.play-icon {
			display: inline-block;
			color: #ffffff;
			font-family: "SFUI Text";
			font-size: 20px;
			line-height: 116px;
			padding-left: 140px;
			font-weight: 700;
			background: url(../img/play-icon.svg) 0 50% no-repeat;
		}
	}
	.text-block {
		text-align: center;
		.text {
			color: #ffffff;
			font-family: "SFUI Text";
			font-size: 18px;
			font-weight: 400;
			line-height: 27px;
			display: inline-block;
			max-width: 380px;
			text-align: left;
			position: relative;
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: -180px;
				top: 50%;
				width: 165px;
				height: 1px;
				background-color: #fff;
				opacity: 0.36;
			}
		}
	}
}

.sec2 {
	padding-top: 150px;
	padding-bottom: 150px;
	position:relative;
	box-shadow: 0 7px 49px rgba(134, 134, 134, 0.2);
	background-color: #ffffff;
	border-bottom: 1px solid #e0e0e0;
	&:before {
		content: '';
		display: block;
		width: 2000px;
		position: absolute;
		top: 0;
		height: 100%;
		right: calc(50% + 300px);
		background-color: #f3f3f3;
		border-right: 1px solid #e0e0e0;
	}
	.block-title {
		max-width: 240px;
		color: #191919;
		font-size: 46px;
		line-height: 48px;
		color: #252525;
		font-family: "Bebas Neue";
		font-weight: 500;
		margin-bottom: 180px;
		b {
			font-weight: 400;
		}
	}
	.line {
		width: 190px;
		height: 1px;
		background-color: #000;
		margin-bottom: 20px;
	}
	.text {
		color: #1c1c1c;
		font-family: "SFUI Text";
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 30px;
	}
	.summ {
		color: #cb4a49;
		font-family: "SFUI Text";
		font-size: 30px;
		font-weight: 500;
	}
	.zp {
		display: none;
		&.active {
			display: block;
		}
	}
	.btn {
		margin-top: 30px;
	}
	.text2 {
		color: #070707;
		font-family: "SFUI Text";
		font-size: 19px;
		font-weight: 400;
		line-height: 27px;
		margin-bottom: 25px;
	}
	.tabs-menu {
		ul {
			display: inline-block;
			vertical-align: top;
			border: 1px solid #cecece;
			border-radius: 3px;
			.tab-title{
				color: #0d0d0d;
				font-family: "SFUI Text";
				font-size: 15px;
				line-height: 48px;
				font-weight: 400;
				display: inline-block;
				vertical-align: top;
				background-color: #ffffff;
				padding-left: 25px;
				padding-right: 25px;
				border-right: 1px solid #cecece;
				cursor: pointer;
				&.active {
					background-color: #e8e8e8;
				}
				&:last-child {
					border: none;
				}
			}
		}
	}
	.tabs-content {
		.tab {
			display: none;
			margin-top: 50px;
			height: 350px;
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: contain;
			&.active {
				display: block;
			}
		}
		.tab1 {background-image: url(../img/tab1.png) }
		.tab2 {background-image: url(../img/tab2.png) }
		.tab3 {background-image: url(../img/tab3.png) }
	}
}

.sec3 {
	background-color: #ffffff;
	padding-top: 145px;
	padding-bottom: 145px;
	.sec-title {
		color: #000000;
		font-family: "Bebas Neue";
		font-size: 68px;
		font-weight: 500;
		line-height: 64px;
		letter-spacing: -1.36px;
		margin-bottom: 120px;
		b {
			font-weight: 400;
		}
	}
	ul {
		.item {
			color: #2d2c2c;
			font-family: "SFUI Text";
			font-size: 18px;
			font-weight: 400;
			line-height: 27px;
			padding-left: 109px;
			max-width: 450px;
			margin-bottom: 50px;
			background-position: 0 50%;
			min-height: 72px;
			background-repeat: no-repeat;
			display: flex;
			align-items: center;
		}
		.item1 {background-image: url(../img/it1.png);}
		.item2 {background-image: url(../img/it2.png);}
		.item3 {background-image: url(../img/it3.png);}
		.item4 {background-image: url(../img/it4.png);}
		.item5 {background-image: url(../img/it5.png);}
		.item6 {background-image: url(../img/it6.png);}
		.item5,
		.item6 {
			margin-bottom: 0;
		}
	}
}

.sec4 {
	box-shadow: 0 7px 49px rgba(134, 134, 134, 0.2);
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
	position:relative;
	padding-top: 145px;
	padding-bottom: 145px;
	&:before {
		content: '';
		display: block;
		width: 2000px;
		position: absolute;
		top: 0;
		height: 100%;
		right: calc(50% + 300px);
		background-color: #2b2b2b;
		border-right: 1px solid #e0e0e0;
	}
	.program-title {
		color: #ffffff;
		font-family: "Bebas Neue";
		font-size: 42px;
		font-weight: 400;
		line-height: 40px;
		max-width: 160px;
		margin-bottom: 70px;
	}
	.program-navigation {
		ul {
			.tab-link {
				color: #595959;
				font-family: "SFUI Text";
				font-size: 14px;
				font-weight: 400;
				cursor: pointer;
				border-radius: 18px;
				text-align: center;
				width: 110px;
				line-height: 40px;
				margin-bottom: 1px;
				margin-left: -22px;
				transition: all 0.2s linear;
				&.active,
				&:hover {
					background-color: #e25050;
					color: #fff;
				}
			}
			.ekvator,
			.defence {
				font-family: "SFUI Text";
				font-size: 14px;
				font-weight: 400;
				color: #fff;
				opacity: 0.66;
				margin-top: 5px;
				margin-bottom: 5px;
				position:relative;
				&:before {
					content: '';
					display: block;
					position: absolute;
					left: -35px;
					top: 5px;
					width: 25px;
					height: 25px;
					background-position: 50%;
					background-repeat: no-repeat;
				}
			}
			.ekvator:before {background-image: url(../img/ekvator-icon.png);}
			.defence:before {background-image: url(../img/defence-icon.png);}
		}
	}
	.module {
		display: none;
		&.active {
			display: block;
		}
		.module-number {
			opacity: 0.98;
			color: #000000;
			font-family: "Bebas Neue";
			font-size: 31px;
			font-weight: 700;
			letter-spacing: 0.62px;
			display: inline-block;
			vertical-align: top;
			position:relative;
			margin-right: 150px;
			margin-bottom: 74px;
			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 88px;
				height: 88px;
				right: -125px;
				top: 50%;
				transform: translateY(-50%);
				border-radius: 50%;
				background-color: #e25050;
				background-position: 50% 0;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
		.module-title {
			display: inline-block;
			vertical-align: top;
			color: #000000;
			font-family: "SFUI Text";
			font-size: 31px;
			font-weight: 300;
			max-width: 470px;
		}
		ul {
			list-style-type: disc;
			max-width: 325px;
			display: inline-block;
			vertical-align: top;
			li {
				color: #000000;
				font-family: "SFUI Text";
				font-size: 18px;
				margin-bottom: 20px;
				font-weight: 300;
			}
		}

	}
	.bottom-block {
		text-align: center;
		border-top: 1px #e6e6e6 solid;
		padding-top: 75px;
		margin-top: 70px;
		.bottom-text {
			color: #000000;
			font-family: "SFUI Text";
			font-size: 23px;
			font-weight: 300;
			line-height: 32px;
			margin-bottom: 70px;
			b {
				font-weight: 700;
			}
		}
	}
	.module1 .module-number:before {background-image: url(../img/speakers/ed.jpg);}
	.module2 .module-number:before {background-image: url(../img/speakers/ed.jpg);}
	.module3 .module-number:before {background-image: url(../img/speakers/dimbrovski.jpg);}
	.module4 .module-number:before {background-image: url(../img/speakers/ed.jpg);}
	.module5 .module-number:before {background-image: url(../img/speakers/movchan.jpg);}
	.module6 .module-number:before {background-image: url(../img/speakers/stepaniyk.jpg);}
	.module7 .module-number:before {background-image: url(../img/speakers/ed.jpg);}
	.module8 .module-number:before {background-image: url(../img/speakers/scherbakov.jpg);}
	.module9 .module-number:before {background-image: url(../img/speakers/ed.jpg);}
	.module10 .module-number:before {background-image: url(../img/speakers/ed.jpg);}
	.module11 .module-number:before {background-image: url(../img/speakers/gydkov.jpg);}
	.module12 .module-number:before {background-image: url(../img/speakers/gapchyk.jpg);}
}

.sec5 {
	padding-top: 145px;
	padding-bottom: 145px;
	.sec-title {
		color: #000000;
		font-family: "Bebas Neue";
		font-size: 46px;
		font-weight: 400;
		line-height: 48px;
	}
	.item {
		margin-left: 40px;
		position: relative;
		padding-bottom: 57px;
		&:last-child {
			&:after {display: none;}
			padding-bottom: 0;
		}
		.item-number {
			position: absolute;
			border: 1px solid #e25050;
			background-color: #ffffff;
			border-radius: 50%;
			text-align: center;
			width: 39px;
			line-height: 39px;
			color: #e25050;
			font-family: "SFUI Text";
			font-size: 17px;
			font-weight: 400;
			left: -83px;
			top: 0;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 40px;
			height: 100%;
			width: 1px;
			left: -64px;
			background-color: #f2b0b0;
		}
		.item-title {
			color: #000000;
			font-family: "SFUI Text";
			font-size: 24px;
			font-weight: 500;
			margin-bottom: 15px;
		}
		.item-text {
			color: #2c2c2c;
			font-family: "SFUI Text";
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			max-width: 600px;
		}
	}
}

.sec6 {
	padding-top: 145px;
	padding-bottom: 75px;
	background: #c34746 url(../img/sec6.jpg) 50% 0 no-repeat;
	background-size: cover;
	.sec-title {
		color: #ffffff;
		font-family: "Bebas Neue";
		font-size: 61px;
		font-weight: 400;
		line-height: 64px;
		margin-bottom: 20px;
	}
	.desc {
		color: #ffffff;
		font-family: "SFUI Text";
		font-size: 19px;
		font-weight: 400;
		line-height: 26px;
		max-width: 800px;
		margin-bottom: 60px;
	}
	.slider {
		.slick-prev,
		.slick-next {
			top: auto;
			bottom: -66px;
			z-index: 10;
		}
		.slick-prev {
			left: 520px;
		}
		.slick-next {
			right: 480px;
		}
		.slick-dots {
			bottom: -60px;
			&:before {
				content: 'из 3';
				display: block;
				color: #ffffff;
				font-family: "Bebas Neue";
				font-size: 23px;
				font-weight: 700;
				bottom: -3px;
				position: absolute;
				left: 51%;
			}
			li {display: none;}
			li.slick-active {
				display: inline-block;
				text-align: center;
				button {
					&:before {display: none;}
					color: #ffffff;
					font-family: "Bebas Neue";
					font-size: 23px;
					font-weight: 700;
				}
			}
		}
	}
	.slide {
		background-color: #fff;
		box-shadow: 0 7px 49px rgba(134, 134, 134, 0.2);
		border: 1px solid #e0e0e0;
		min-height: 492px;
		max-width: 941px;
		margin: 0 50px;
		background-position: 50%;
		background-repeat: no-repeat;
	}
	.slide1 {background-image: url(../img/slide1.jpg);}
	.slide2 {background-image: url(../img/slide2.png);}
	.slide3 {background-image: url(../img/slide3.png);}
}

.sec7 {
	padding-top: 145px;
	padding-bottom: 145px;
	.sec-title {
		color: #000000;
		font-family: "Bebas Neue";
		font-size: 68px;
		font-weight: 400;
		line-height: 64px;
		margin-bottom: 50px;
	}
	.speakers {
		.slick-prev,
		.slick-next {
			top: auto;
			bottom: -66px;
			z-index: 10;
			&:before {
				color: #000;
			}
		}
		.slick-prev {
			left: 520px;
		}
		.slick-next {
			right: 480px;
		}
		.slick-dots {
			bottom: -60px;
			&:before {
				content: 'из 7';
				display: block;
				color: #000;
				font-family: "Bebas Neue";
				font-size: 23px;
				font-weight: 700;
				bottom: -3px;
				position: absolute;
				left: 51%;
			}
			li {display: none;}
			li.slick-active {
				display: inline-block;
				text-align: center;
				button {
					&:before {display: none;}
					color: #000;
					font-family: "Bebas Neue";
					font-size: 23px;
					font-weight: 700;
				}
			}
		}
	}
	.speaker {
		width: 100%;
		max-width: 460px;
		position: relative;
		margin-right: 50px;
		margin-left: 50px;
		.speaker-info-wrap {
			background-color: #cfcfcf;
			box-shadow: 0 7px 24px rgba(97, 97, 97, 0.29);
			height: 388px;
			margin-bottom: 30px;
			.speaker-photo {
				background-position: 50% 0;
				background-repeat: no-repeat;
				position: relative;
				background-size: cover;
				height: 100%;
				width: 365px;
			}
			.speaker-prof {
				background-color: #ffffff;
				position: absolute;
				height: auto;
				width: 388px;
				bottom: 0;
				right: 0;
				transform: rotate(-90deg) translate(245px, 147px);
				color: #000000;
				font-family: "SFUI Text";
				font-size: 18px;
				font-weight: 300;
				line-height: 27px;
				padding-bottom: 20px;
				padding-top: 20px;
				padding-left: 30px;
				overflow: hidden;
				z-index: 2;
			}
		}
		.speaker-name {
			color: #000000;
			font-family: "Bebas Neue";
			font-size: 42px;
			font-weight: 400;
			margin-bottom: 10px;
		}
		.speaker-info {
			position: absolute;
			width: 100%;
			height: 388px;
			overflow: hidden;
			top: 0;
			left: 0;
			background: rgba(0,0,0,.9);
			padding: 20px;
			padding-right: 120px;
			padding-left: 40px;
			opacity: 0;
			transition: .2s linear;
			z-index: 1;
			li {
				font-family: "SFUI Text";
				font-size: 15px;
				line-height: 20px;
				color: #fff;
				list-style: disc;
				margin-bottom: 10px;
			}
			&:hover {
				opacity: 1;
			}
		}
		&.speaker1 .speaker-photo {background-image: url(../img/speakers/ed.jpg);}
		&.speaker2 .speaker-photo {background-image: url(../img/speakers/dimbrovski.jpg);}
		&.speaker3 .speaker-photo {background-image: url(../img/speakers/stepaniyk.jpg);}
		&.speaker4 .speaker-photo {background-image: url(../img/speakers/movchan.jpg);}
		&.speaker5 .speaker-photo {background-image: url(../img/speakers/scherbakov.jpg);}
		&.speaker6 .speaker-photo {background-image: url(../img/speakers/gydkov.jpg);}
		&.speaker7 .speaker-photo {background-image: url(../img/speakers/gapchyk.jpg);}
	}
}

.sec8 {
	padding-top: 145px;
	box-shadow: 0 7px 49px rgba(134, 134, 134, 0.2);
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
	.packages-mobile {display: none;}
	.block-title {
		color: #000000;
		font-family: "Bebas Neue";
		font-size: 42px;
		font-weight: 400;
		line-height: 40px;
		max-width: 270px;
	}
	.package-type {
		height: 90px;
		background-position: 50% 0;
		background-repeat: no-repeat;
		margin-bottom: 40px;
		&.online {background-image: url(../img/online.png);}
		&.offline {background-image: url(../img/offline.png);}
	}
	.table-title {
		margin-bottom: 40px;
	}
	.packages-info,
	.additional {
		// max-width: 320px;
		li {
			display: flex;
			min-height: 77px;
			&:nth-child(odd) {
				background-color: rgba(199, 199, 199, 0.15);
			}
			p {
				align-self: center;
				color: #000000;
				font-family: "SFUI Text";
				font-size: 17px;
				font-weight: 400;
			}
		}
	}
	.package-name {
		color: #000000;
		font-family: "Bebas Neue";
		font-size: 30px;
		font-weight: 400;
		line-height: 40px;
		text-align: center;
		&.red {
			color: #f6473b;
		}
	}
	.package-info {
		li {
			min-height: 77px;
			background-repeat: no-repeat;
			background-position: 50%;
			&:nth-child(odd) {
				background-color: rgba(199, 199, 199, 0.15);
			}
			&.check {
				background-image:url(../img/check.svg);
			}
			&.check.additional {
				background-image: url(../img/check-red.svg);
			}
		}
	}
	.additional {
		li {
			span {
				color: #ffffff;
				font-family: "Bebas Neue";
				font-size: 19px;
				font-weight: 700;
				display: inline-block;
				background-color: #e25050;
				line-height: 27px;
				padding-left: 7px;
				padding-right: 7px;
			}
		}
	}
	.package-price-block {
		margin-top: 70px;
		text-align: center;
		padding-bottom: 100px;
		.price-now-text {
			color: #000000;
			font-family: "Bebas Neue";
			font-size: 19px;
			font-weight: 700;
			line-height: 23px;
		}
		.price-usd {
			color: #f15555;
			font-family: "Bebas Neue";
			font-size: 42px;
			font-weight: 400;
			line-height: 40px;
			margin-bottom: 10px;
		}
		.price {
			display: inline-block;
			max-width: 120px;
			color: #000000;
			font-family: "SFUI Text";
			font-size: 15px;
			font-weight: 400;
			margin-bottom: 25px;
		}
		.btn {
			margin-bottom: 20px;
		}
		.places-left {
			color: #8c8c8c;
			font-family: "Bebas Neue";
			font-size: 19px;
			font-weight: 700;
			span {
				line-height: 20px;
				width: 25px;
				height: 15px;
				display: inline-block;
				background: url(../img/places-icon.svg) 50% 50% no-repeat;
			}
		}

	}
	.tc1 {
		background-color: #f9f9f9;
	}
	.tc2 {background-color: #f2f2f2;}
	.tc3 {background-color: #ebebeb;}

}

.sec9 {
	background: url(../img/sec1.jpg) 50% no-repeat;
	background-size: cover;
	padding-top: 145px;
	padding-bottom: 145px;
	.sec-title {
		color: #ffffff;
		font-family: "Bebas Neue";
		font-size: 68px;
		font-weight: 400;
		line-height: 64px;
		max-width: 860px;
		margin-bottom: 50px;
	}
	.videreviews-slider {
		.slick-prev,
		.slick-next {
			top: auto;
			bottom: -66px;
			z-index: 10;
		}
		.slick-prev {
			left: 520px;
		}
		.slick-next {
			right: 480px;
		}
		.slick-dots {
			bottom: -60px;
			&:before {
				content: 'из 3';
				display: block;
				color: #ffffff;
				font-family: "Bebas Neue";
				font-size: 23px;
				font-weight: 700;
				bottom: -3px;
				position: absolute;
				left: 51%;
			}
			li {display: none;}
			li.slick-active {
				display: inline-block;
				text-align: center;
				button {
					&:before {display: none;}
					color: #ffffff;
					font-family: "Bebas Neue";
					font-size: 23px;
					font-weight: 700;
				}
			}
		}
	}
	.videoreview {
		max-width: 460px;
		margin-right: 60px;
		margin-left: 60px;
		.video {
			height: 328px;
			background: url(../img/videreviewbg.jpg) 50% no-repeat;
			background-size: cover;
			position: relative;
			margin-bottom: 34px;
			.play-icon {
				position: absolute;
				width: 90px;
				height: 90px;
				background: url(../img/play-icon2.svg) 50% no-repeat;
				border-radius: 50%;
				left: 50%;
				top: 50%;
				margin-left: -45px;
				margin-top: -45px;
			}
		}
		.author-name {
			color: #ffffff;
			font-family: "Bebas Neue";
			font-size: 42px;
			font-weight: 400;
		}
		.author-info {
			color: #ffffff;
			font-family: "SFUI Text";
			font-size: 18px;
			font-weight: 300;
			line-height: 27px;
		}
	}
}


.sec10 {
	padding-top: 145px;
	padding-bottom: 80px;
	.sec-title {
		color: #000000;
		font-family: "Bebas Neue";
		font-size: 68px;
		font-weight: 400;
		line-height: 64px;
		margin-top: 34px;
	}
	.question-block {
		padding-left: 54px;
		padding-bottom: 40px;
		padding-top: 40px;
		padding-right: 100px;
		border-top: 1px solid #e5e5e5;
		position: relative;
		&.active {
			border: 1px solid #f1b0af;
			// .answer {
			// 	display: block;
			// }
			.icon {
				background: url(../img/close-icon.png) 50% no-repeat;
			}
		}
		.icon {
			position: absolute;
			width: 54px;
			height: 54px;
			border-radius: 50%;
			background: url(../img/open-icon.png) 50% no-repeat;
			top: 30px;
			right: 30px;
			cursor: pointer;
		}
		.question {
			color: #000000;
			font-family: "SFUI Text";
			font-size: 18px;
			font-weight: 700;
			line-height: 26px;
		}
		.answer {
			display: none;
			color: #000000;
			font-family: "SFUI Text";
			font-size: 17px;
			font-weight: 400;
			line-height: 26px;
			margin-top: 20px;
		}
	}
	.btn-wrap {
		margin-top: 70px;
		text-align: center;
	}
}