.footer1,
.footer2,
.footer3 {
	vertical-align: top;
	display: inline-block;}
footer {
	font-family: Roboto;
	font-weight: 500;
	vertical-align: top;
	padding-bottom: 35px;
	width: 100%;
	min-height: 250px;
	background-color: #2f2f2f;
	text-align: center; }
	footer br.sm {
		display: none; }
	footer br.xs {
		display: none; }
	footer p {
		color: #fff;
		font-size: 13px;
		line-height: 20px;
		text-align: left;}
	footer .social {
		margin-bottom: 30px;
		text-align:center; }
	footer a {
		padding: 0 10px;
		padding-left:0px;
		color: #fff;
		font-size: 13px;
		transition: all 0.3s linear;
		text-align: left;}
	footer a:hover {
		text-decoration: none; }
	footer a.partners {
		display: block;
		margin: 0 auto;
		margin-top: 30px;
		width: 250px;
		height: 50px;
		line-height: 50px;
		color: #f45c33;
		border: 1px solid #f45c33; }
	footer a.partners:hover {
		color: #fff;
		background-color: #f45c33; }
	footer .contacts {
		margin-top: 0; }
	footer .copy {
		color: #8d8d8d;
		margin-top: 44px;
		margin-bottom: 0; }
	footer .icon-soc {
		display: inline-block;
		width: 35px;
		height: 35px;
		vertical-align: top;
		margin: 0px 20px;
		background: #fff;
		border-radius:50%;
		text-align:center;
		color:#2f2f2f;
		padding:0px;
		i{
			text-align:center;
			font-size:20px;
			line-height:35px;
			margin: 0 auto;
		 }
		}
	footer a.icon-soc:hover {
		opacity: 0.8; }
	footer .icon-fb {
		margin-left:0px;
	}
	footer .icon-yt {
		margin-right:0px;
	}
	footer .modal-btn{
		padding-left:20px;
		display:block;
		margin-top:0px;
		margin-bottom: 14px;
	}
	footer .modal-btn:before{
		content:'\f00c';
		font-family:FontAwesome;
		margin-left:-10px;
		margin-right:10px;
		color:#848484;
	}
	.footer2{
		padding-top:60px;
		margin-left:30px;
	}
	.footer3{
		padding-top:58px;
		margin-left:30px;
	}
	.footer3 p.text4{
		line-height: 25px;
		text-align:right;
		font-family: Roboto;
		font-weight: 400;
	}
	.footer3 p.text4 span a{
		font-size: 16px;
		padding-right: 70px;
		float:left;
		font-family: Roboto;
		font-weight: 700;
	}
	.footer-line {
		display: inline-block;
		margin-top: 35px;
		margin-bottom: 35px;
		width: 90%;
		height: 1px;
		background-color: #6c6c6c;
		opacity: 0.3;
	}
	footer .bottom-text {
		font-size: 13px;
		line-height: 22px;
		text-align: center;
		color: #8d8d8d;
	}
	footer a.mail {
		background-color: #f8ff44;
		color: #636475;
		font-weight: 700;
	}
@media only screen and (max-width : 1130px) {
	footer .footer3{
		margin-left:0px;
	}
}

@media only screen and (max-width : 720px) {

	footer .copy {
			margin: 24px auto;
			display: block;
	}
	.footer2 {
			padding-top: 0px;
			margin: 0 auto;
			display: block;
	}
	footer p {
			color: #979898;
			font-size: 13px;
			text-align: center;
	}
	footer a.icon-soc {
			display: inline-block;
			width: 35px;
			height: 35px;
			vertical-align: top;
			margin: 0px 10px;
	}
	.footer3 p.text4 span {
			color: #636475;
			font-size: 16px;
			padding-right: 20px;
	}
	.footer-inline {
		padding-left: 50px;
		padding-top:20px;
	}

	.footer3 {
			padding-top: 38px;
			margin-left: 0px;
	}

	footer .bottom-text br {display: none;}

}