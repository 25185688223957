.modal-overlay {
	display: none;
	padding-top: 1px;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(0,0,0,0.5);
	z-index: 1000000;
	overflow: auto;
	.modal-content {
		margin: 10vh auto;
		width: 60vw;
		padding:30px;
		background:#fff;
		margin-bottom: 0px;
		border-radius: 4px;
		height:70vh;
	}
	.close-button {
		position: absolute;
		width: 50px;
		height: 50px;
		top: 30px;
		right: 40px;
		padding: 0px 14px;
		padding-top: 4px;
		cursor: pointer;
		transition: 0.2s linear;
		.one {
			height: 50px;
			width: 2px;
			background-color: #fff;
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transition: 0.2s linear;
		}
		.two {
			margin-top: -50px;
			height: 50px;
			width: 2px;
			background-color: #fff;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transition: 0.2s linear;
		}
		&:hover {
			.one {
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transition: 0.2s linear;
				background-color: #000;
			}
			.two {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transition: 0.2s linear;
				background-color: #000;
			}
		}
	}
}

#modal-video {
	.modal-content {
		height: auto;
		width: 100%;
	}
	.video {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}